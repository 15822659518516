#tutorial {
  padding: #{$padding * 3} 10%;

  text-align: center;

  @media screen and (min-width: 950px) {
    padding: #{$padding * 5} 15%;
  }

  a {
    display: inline-block;

    padding: $padding #{$padding * 2};

    // background-color: $accent;
    border: 1px solid $accent;

    text-decoration: none;
    color: $accent;

    font-weight: bold;


    &:hover,
    &:focus {
      opacity: 0.5;
    }
  }
}
