#about {
  display: flex;

  flex-wrap: wrap;
  align-items: flex-start;

  padding: #{$padding * 3} 10% 0;

  @media screen and (min-width: 950px) {
    padding: #{$padding * 5} 15% 0;
  }
}

.about-speel {
  font-size: $big-type;

  text-align: center;
  color: $accent;

  margin-bottom: $padding;
}

.about-content {
  display: flex;

  flex-direction: row;

  flex: 0 0 100%;

  justify-content: flex-start;
  align-items: flex-start;

  margin: #{$padding * 3} 0;

  text-align: left;

  p {
    font-size: $normal-type;

    color: $accent;

    margin: 0;
    padding: 0;
  }

  svg {
    width: 48px;

    fill: currentColor;
  }

  .icon {
    color: $accent;

    flex: 0 0 100px;
  }

  @media screen and (min-width: 480px) {
    p {
      padding: 12px 0 0;
    }
  }

  @media screen and (min-width: 768px) {
    p {
      font-size: $big-type;
      padding: 0;
    }
  }

  @media screen and (min-width: 950px) {
    flex-direction: column;
    align-items: center;

    flex: 0 0 calc(100% / 3);

    text-align: center;

    .icon {
      flex: 0 0 auto;
      margin-bottom: 10px;
    }

    p {
      font-size: $normal-type;

      padding: 0 20px;
    }
  }
}
