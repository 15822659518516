#fold {
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  min-height: 100vh;

  @media screen and (min-width: 768px) {
    min-height: calc(100vh + 50px);
  }
}

.title {
  display: flex;

  align-items: center;
  justify-content: center;

  flex: 1 1 auto;

  margin: 0;
  padding-top: 80px;

  span {
    display: none;

    text-transform: uppercase;

    color: $accent;
    font-size: $xlarge-type;

    padding: 12px 0;

    &:last-of-type {
      font-size: $xxlarge-type;

      padding: 0;
    }
  }
}

.screens {
  width: 80%;

  margin-bottom: 10vh;

  overflow: hidden;

  border-radius: 6px;
  line-height: 0;

  box-shadow: 0 -4px 35px rgba(0,0,0,0.15);

  img {
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    width: 50%;
    max-width: 800px;

    margin-bottom: 0;
  }

  @media screen and (min-width: 1280px) {
    width: 65%;
  }
}

.title,
.screens {
  position: relative;

  z-index: 10;
}

.overlay {
  position: absolute;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  line-height: 1.4;

  background: linear-gradient(to bottom left, $accent, adjust-hue($accent, 330));

  opacity: 0;
  visibility: hidden;

  transition: {
    duration: 0.35s;
    property: opacity, visibility;
  }

  .screens:hover & {
    opacity: 1;
    visibility: visible;
  }

  span {
    display: block;

    margin-top: 6px;

    font-size: $small-type;
  }
}

.gifs {
  position: absolute;

  margin-top: 80px;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  z-index: 5;

  opacity: 0.25;

  overflow: hidden;

  img {
    position: absolute;

    width: calc(var(--width-modifier) * 10vw);
  }

  @media screen and (min-width: 768px) {
    opacity: 0.85;
  }
}
