.update-overlay {
  position: fixed;

  top: 0;
  left: 0;

  z-index: 50;

  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparentize($accent, 0.25);

  opacity: 0;
  visibility: hidden;

  transition: {
    duration: 0.5s;
    property: opacity, visibility;
  }

  &__content {
    max-width: 75vw;

    padding: $padding * 2;

    background-color: white;

    text-align: center;

    box-shadow: 0 0 10px -2px rgba(0,0,0,0.5);

    p {
      margin: 0 0 $padding;

      &:first-of-type {
        font-size: $large-type;
      }

      &:last-of-type {
        margin: 0;
      }
    }
  }

  &.show {
    opacity: 1;
    visibility: visible;
  }
}
