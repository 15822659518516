@import 'normalize.css';

$primary: #333;
$light: #aaa;
$accent: #ff402f;
$padding: 24px;

$small-type: 12px;
$normal-type: 16px;
$big-type: 24px;
$large-type: 36px;
$xlarge-type: 56px;
$xxlarge-type: 72px;

* {
  box-sizing: border-box;
}

html,
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: bold;

  color: $primary;

  line-height: 1.5;
  font-size: $normal-type;
}

.downloader {
  position: relative;

  display: inline-block;

  padding: #{$padding / 2} #{$padding * 2};

  border-radius: 30px;

  background-color: white;
  color: $accent;

  text-decoration: none;

  overflow: hidden;

  transition: {
    duration: 0.35s;
    property: box-shadow;
  }

  &:before {
    position: absolute;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: red;

    opacity: 0.25;

    transform: scaleY(0);
    // transform-origin: bottom left;

    will-change: transform;

    transition: {
      duration: 0.35s;
      property: transform;
    }

    content: '';
  }

  &:hover {
    box-shadow: 0 0 10px -2px rgba(0,0,0,0.5);

    &:before {
      transform: scaleY(1);
    }
  }
}

@import 'parts/header';
@import 'parts/fold';
@import 'parts/about';
@import 'parts/tutorial';
@import 'parts/footer';
@import 'parts/ph';
@import 'parts/updates';
